<template>
  <div class="build views"
       :style="viewStyle">
    <container :id="id"
               :target="target"
               :option="option"
               ref="container"></container>
  </div>
</template>
<script>
import init from '@smallwei/avue-data/mixins/'
import registerAxios from '@smallwei/avue-data/axios';
export default {
  props: {
    target: String,
    option: Object,
    id: [String, Number]
  },
  mixins: [init],
  data () {
    return {
      viewStyle: {}
    }
  },
  created () {
    registerAxios(axios);
  }
}
</script>